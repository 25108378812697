<template>
  <div>
    <div style="float: left; width: 100%">
      <b-table
        responsive
        :fields="fields"
        :items="dataLog"
        show-empty
        class="scrollable-element user-clock-in-out-log mb-5 tableDesigns"
      >
        <template #cell(in_time)="data">

          <div class="d-flex cursor-default align-items-center">
            <b-badge
              pill
              :variant="data.item.deleted_at ? 'danger' : 'success'"
              style="color: transparent;height: 15px;width: 15px; background-color: #23bc74;"
            >
              .
            </b-badge>
            <p :class="data.item.deleted_at ? 'text-danger ml-2 text-uppercase': 'ml-2 text-uppercase tabletxtColor'">{{ data.item.in_time | fullTimeFormat
            }}
            </p>
            <small
              v-if="data.item && (data.item.is_updated_in == true) && data.item.updated_by && ! (data.item.user_id == data.item.updated_by.id)"
              class="mt-25 ml-25"
            >edited by {{ data.item.updated_by.full_name }} </small>
          </div>
        </template>
        <template #cell(out_time)="data">

          <div
            v-if="data.item.out_time"
            class="d-flex cursor-default align-items-center"
          >
            <b-badge
              pill
              :variant="data.item.deleted_at ? 'danger' : 'secondary'"
              style="color: transparent;height: 15px;width: 15px;background-color: #23bc74;"
            >
              .
            </b-badge>
            <p :class="data.item.deleted_at ? 'text-danger ml-2 text-uppercase': 'ml-2 text-uppercase tabletxtColor'">{{ data.item.out_time | fullTimeFormat }}
            </p>
            <small
              v-if="data.item && (data.item.is_updated_out == true) && data.item.updated_by && ! (data.item.user_id == data.item.updated_by.id)"
              class="mt-25 ml-25"
            >edited by {{ data.item.updated_by.full_name }} </small>
          </div>
          <div
            v-else
            class="d-flex cursor-default"
          >
            <p class="ml-2"> -
            </p>
          </div>

        </template>
        <template #cell(time_diff)="data">

          <span
            v-if="data.item.out_time !== null"
            :class="data.item.deleted_at ? 'text-danger cursor-default': 'cursor-default tabletxtColor'"
          >
            {{ secondsToTime(data.item.time_diff) }}
          </span>
          <div
            v-if="(data.item.time_diff == 0) && data.item.out_time==null"
            class="d-flex cursor-default"
          >
            <p class="ml-2"> -
            </p>
          </div>
        </template>
        <template #cell(action)="data">
          <span>

            <feather-icon
              v-b-tooltip.hover.top="'Edit'"

              icon="EditIcon"
              :class="data.item.deleted_at ? 'mr-1  text-danger disableClass':'mr-1 text-dark'"
              size="18"
              role="button"
              @click="edit(data.item)"
            />
            <feather-icon
              v-b-tooltip.hover.top="'Delete'"
              role="button"
              icon="Trash2Icon"
              color="red"
              :class="data.item.deleted_at ? 'text-danger disableClass':''"
              size="18"
              @click="deleteConfirmation(data)"
            />
          </span>
        </template>
      </b-table>

      <div v-if="dataLog.length === 0">
        <img
          src="../../../assets/images/nodatafound/nodata.svg"
          alt="no-data"
          class="no-data-img"
        >
      </div>
    </div>
    <!-- add/edit user form start -->
    <InOutUpdateForm ref="updateLog" />
  </div>
</template>

<script>
import { eventBus } from '@/main'
import * as moment from 'moment/moment'
import InOutUpdateForm from '../table-from/inOutUpdateForm.vue'

export default {
  components: {
    InOutUpdateForm,
  },

  props: {
    /**
     * User Personal Log List
     * @type {Array}
     */
    userId: {
      default: null,
      required: false,
      type: String,
    },
    dataLog: {
      default: () => ([]),
      required: false,
      type: Array,
    },
  },
  data() {
    return {
      logData: [],
    }
  },
  computed: {
    fields() {
      const commonField = [
        {
          key: 'in_time',
          label: 'In',
          sortable: false,
          thStyle: { width: '200px' },
          tdStyle: { width: '200px' },
        },
        {
          key: 'out_time',
          label: 'Out',
          sortable: false,
          thStyle: { width: '200px' },
          tdStyle: { width: '200px' },
        },
        {
          key: 'time_diff',
          label: 'Time',
          sortable: false,
          thStyle: { width: '97px' },
          tdStyle: { width: '97px' },
        }]

      if (this.userInfo.role === 'SA' || (this.userInfo.role === 'HR' && this.userId)) {
        commonField.push({
          key: 'action',
          label: 'action',
          sortable: false,
          thStyle: { width: '97px' },
          tdStyle: { width: '97px' },
        })
      }
      return commonField
    },
  },

  mounted() {},
  methods: {

    edit(data) {
      if (data.deleted_at) {
        return
      }
      this.$refs.updateLog.id = data.id
      this.$refs.updateLog.inTime = data.in_time
      this.$refs.updateLog.outTime = data.out_date && data.out_time ? moment(`${data.out_date} ${data.out_time}`, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : null
      this.$refs.updateLog.in_date = data.in_date
      this.$refs.updateLog.out_date = data.out_date
      this.$root.$emit('bv::toggle::collapse', 'updateLog')
    },

    async deleteConfirmation(data) {
      if (data.item.deleted_at) {
        return
      }
      const value = await this.conformationAlert(true)
      if (value.isConfirmed === true) {
        this.deleteLog(data.item.id)
      }
    },
    /**
     * @description delete client
     * @method DELETE
     * @param {id}  client name
     * @returns delete Client
     */
    async deleteLog(id) {
      const response = await this.getHTTPDeleteResponse(
        `clock-in-out/delete/${id}`,
        {},
        true,
      )
      if (response && response.status === 200) {
        setTimeout(() => eventBus.$emit('logListReLoad', true), 500)
      }
    },
  },
}
</script>
<style>
.disableClass{
cursor:not-allowed;
}
</style>
